var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mt-2 ml-3 mr-5"},[_c('h2',{staticClass:"card-header no-bb"},[_vm._v("Service Appointments")]),_c('responsive-search',{ref:"search",attrs:{"storage-key":_vm.storageKey,"fields":_vm.searchFields},on:{"searchButtonClick":_vm.searchButtonClick,"resetButtonClick":_vm.resetButtonClick}}),_c('b-row',{staticClass:"mt-1"},[_c('b-form-group',[_c('b-input-group',[_c('b-form-input',{attrs:{"id":"filterInput","variant":"position-relative","type":"search","placeholder":"Type to Filter","debounce":500},model:{value:(_vm.filter),callback:function ($$v) {_vm.filter=$$v},expression:"filter"}})],1)],1),_c('b-pagination',{staticClass:"ml-auto w-auto",attrs:{"total-rows":_vm.totalRows,"per-page":_vm.pageSize,"aria-controls":"my-table","align":"right"},model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}})],1),_c('b-table',{ref:"serviceCalendarTable",staticClass:"border mt-3",attrs:{"striped":"","sticky-header":"calc(69vh - 82px - 60px)","items":_vm.updateSearchResults,"fields":_vm.fields,"per-page":_vm.pageSize,"current-page":_vm.currentPage,"filter":_vm.filter,"filter-ignored-fields":['units'],"sort-by":_vm.sortBy,"sort-compare":_vm.sortCompare,"show-empty":"","empty-text":"No results found.","empty-filtered-text":"No results found."},on:{"update:sortBy":function($event){_vm.sortBy=$event},"update:sort-by":function($event){_vm.sortBy=$event},"filtered":_vm.onFiltered},scopedSlots:_vm._u([{key:"cell(appointmentStatusId)",fn:function(ref){
var item = ref.item;
return [_c('b-badge',{style:(_vm.badgeStyleByType(item)),attrs:{"variant":"light"}},[_vm._v(" "+_vm._s(_vm.badgeText(item))+" ")])]}},{key:"cell(startDate)",fn:function(ref){
var item = ref.item;
return [(item.startDate)?_c('div',[_vm._v(" "+_vm._s(_vm._f("date")(item.startDate,'LL'))+" "),_c('br'),_vm._v(" "+_vm._s(_vm._f("hourWithMinutes")(item.startDate))+" - "+_vm._s(_vm._f("hourWithMinutes")(item.endDate))+" ")]):_vm._e(),(item.serviceDateRequested !== undefined)?_c('div',{staticClass:"note"},[_vm._v(" Requested "),_c('br'),_vm._v(" "+_vm._s(_vm._f("dateOrEmptyString")(item.serviceDateRequested,'LL'))+" ")]):_vm._e()]}},{key:"cell(contactName)",fn:function(ref){
var item = ref.item;
return [(item.contactName)?_c('div',[_vm._v(" "+_vm._s(item.contactName && item.contactName.length > 2 ? item.contactName : _vm.NA)+" "),_c('br'),_vm._v(" "+_vm._s(_vm._f("dateOrEmptyString")(item.dateCreate,'LL'))+" ")]):_vm._e()]}},{key:"cell(actions)",fn:function(ref){
var item = ref.item;
return [_c('b-button',{on:{"click":function($event){return _vm.openModal(item)}}},[_vm._v("VIEW")])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }