<template>
  <div>
    <b-card no-body class="page-card overflow-hidden d-flex flex-column">
      <div
        class="
          topbar-container
          b-widget b-container b-toolable b-dock-top b-top-toolbar b-hbox b-first-visible-child b-resize-monitored
        "
      >
        <div class="topbar b-box-center b-content-element b-auto-container b-hbox b-flex-row b-overflow">
          <b-button :disabled="displaySummaryView" class="tbar-item" size="sm" @click="toggleSidebar">
            <font-awesome-icon icon="bars" fixed-width />
          </b-button>
          <b-button :disabled="displaySummaryView" class="tbar-item" @click="calendar.shiftToNow()">Today</b-button>
          <b-button :disabled="displaySummaryView" class="tbar-item" size="sm" @click="calendar.shiftPrevious()">
            <font-awesome-icon icon="chevron-left" fixed-width />
          </b-button>
          <b-button :disabled="displaySummaryView" class="tbar-item" size="sm" @click="calendar.shiftNext()">
            <font-awesome-icon icon="chevron-right" fixed-width />
          </b-button>
          <div v-show="!displaySummaryView" class="tbar-item topbar-calendar-view">{{ displayDate }}</div>
          <div class="tbar-fill" />
          <b-form-radio-group
            v-model="mode"
            class="tbar-item"
            :options="calendarViewOptions"
            buttons
            @change="changeCalendarView"
          />
          <div class="tbar-item d-flex">
            <b-button
              v-b-tooltip.hover="`${showServiceRequests ? 'Hide' : 'Show'} Service Requests`"
              :disabled="displaySummaryView"
              size="sm"
              :class="`fa-2x ml-2 ${!displaySummaryView ? 'btn-icon' : ''} ${
                showServiceRequests ? 'text-secondary' : 'text-muted'
              }`"
              style="padding: 2px"
              @click="showServiceRequests = !showServiceRequests"
            >
              <img src="/images/request-service.svg" width="28" height="auto" class="" alt="Service Requests" />
            </b-button>
            <b-badge
              v-if="!displaySummaryView && $refs.requests && $refs.requests.numberOfServiceRequests > 0"
              class="icon-badge"
              variant="danger"
            >
              {{ numberOfServiceRequests }}
            </b-badge>
          </div>
        </div>
      </div>
      <div v-show="!displaySummaryView" id="content-container" class="content-container">
        <bryntum-calendar
          v-show="!displaySummaryView"
          ref="calendar"
          v-bind="{ ...calendarConfig, modes }"
          :listeners="listeners"
        />
        <bryntum-splitter v-show="showServiceRequests && !displaySummaryView" />
        <transition v-show="!displaySummaryView" name="panel">
          <service-request-component
            v-show="showServiceRequests"
            ref="requests"
            @setServiceRequests="setServiceRequests"
            @open="openModal"
          />
        </transition>
      </div>
      <appointment-summary-component
        v-if="displaySummaryView"
        :appointments-and-requests="combinedAppointmentsAndServiceRequests"
        :storage-key="storageKey"
        @openModal="openModal"
        @updateRouteQuery="updateRouteQuery"
      ></appointment-summary-component>
    </b-card>

    <appointment-modal :appointment="openAppointment" @close="openAppointment = null" />
  </div>
</template>

<script>
// vuex
import { mapGetters } from 'vuex';
import { LookupGetters } from '@/shared/store/lookup/types';
// helpers
import Cloner from '@/shared/helpers/cloner';
import moment from 'moment-timezone';
import SchedulerService from '@/shared/services/SchedulerService';
import ErrorService from '@/shared/services/ErrorService';
// bryntum
import '@bryntum/calendar/calendar.stockholm.css';
import { BryntumCalendar, BryntumSplitter } from '@bryntum/calendar-vue';
import AppointmentSummaryComponent from './AppointmentSummaryComponent';
import { calendarConfig } from '@/vcp/config/calendarConfig.js';

export default {
  name: 'ServiceCalendarPage',
  components: {
    bryntumCalendar: BryntumCalendar,
    bryntumSplitter: BryntumSplitter,
    'service-request-component': () => import('./ServiceRequestComponent'),
    'appointment-modal': () => import('./AppointmentModal'),
    'appointment-summary-component': AppointmentSummaryComponent
  },
  async beforeRouteUpdate(to, from, next) {
    next();
    this.refreshView();
  },
  data() {
    return {
      storageKey: 'serviceCalendar',
      loading: true,
      calendar: {},
      calendarConfig,
      openAppointment: null,
      listeners: {
        beforeEventEdit: this.beforeEventEdit,
        activeItemChange: this.activeItemChange,
        dateRangeChange: this.dateRangeChange,
        change: this.changeEvent
      },
      calendarViewOptions: [
        { text: 'Day', value: 'day' },
        { text: 'Week', value: 'week' },
        { text: 'Month', value: 'month' },
        { text: 'Year', value: 'year' },
        { text: 'Summary', value: 'summary' }
      ],
      displayDate: null,
      displaySummaryView: false,
      showServiceRequests: true,
      appointments: [],
      serviceRequests: []
    };
  },
  computed: {
    ...mapGetters({
      isFeatureFlagEnabled: LookupGetters.IS_FEATURE_FLAG_ENABLED,
      myBranchMap: LookupGetters.GET_MY_BRANCHES_MAP,
      appointmentStatusList: LookupGetters.GET_APPOINTMENT_STATUS_LIST
    }),
    numberOfServiceRequests() {
      return this.serviceRequests.length;
    },
    vcpServiceRequestFlagEnabled() {
      return this.isFeatureFlagEnabled('VcpServiceRequest');
    },
    appointmentStatuses() {
      return this.appointmentStatusList.filter(x => x.config.showCalendar);
    },
    combinedAppointmentsAndServiceRequests() {
      var midnightLastNight = new Date();
      midnightLastNight.setHours(0, 0, 0, 0);

      let futureAppointments = this.appointments.filter(
        item => item.endDate && new Date(item.endDate) >= midnightLastNight
      );

      const combinedItems = [...futureAppointments, ...this.serviceRequests];
      return combinedItems;
    },
    mode: {
      get() {
        return this.$route.query.mode;
      },
      set(mode) {
        this.calendarConfig.mode = mode;
      }
    },
    date: {
      get() {
        return this.$route.query.date;
      },
      set(date) {
        this.calendarConfig.date = moment(date).toDate();
      }
    },
    modes() {
      return {
        year: true,
        month: {
          eventRenderer: this.monthEventRenderer
        },
        week: {
          eventRenderer: this.dayAndWeekEventRenderer,
          allDayEvents: {
            eventRenderer: this.monthEventRenderer
          }
        },
        day: {
          eventRenderer: this.dayAndWeekEventRenderer,
          allDayEvents: {
            eventRenderer: this.monthEventRenderer
          }
        }
      };
    }
  },
  beforeMount() {
    if (!this.vcpServiceRequestFlagEnabled) this.$router.replace('Default');
    this.refreshView();
  },
  async mounted() {
    if (this.$route.query.mode === 'summary') {
      this.changeCalendarView('summary');
    }

    this.calendar = this.$refs.calendar.instance;
    await this.loadAppointments();
  },
  methods: {
    async loadAppointments() {
      try {
        const calendar = this.$refs.calendar.instance;
        const response = await SchedulerService.getAppointmentsForUser();
        // bug fix - bryntum calendar doesn't handle js Dates well and causing lag on load.
        // switching them to strings
        const appointments = response.data.map(x => {
          return { ...x, startDate: x.startDate.toString(), endDate: x.endDate.toString() };
        });
        this.appointments = appointments;
        await calendar.resourceStore.loadDataAsync(this.appointmentStatuses);
        await calendar.eventStore.loadDataAsync(appointments);
      } catch {
        ErrorService.createErrorToast(this, 'Error loading Appointments.');
      } finally {
        this.loading = false;
      }
    },
    changeCalendarView(selectedView) {
      if (selectedView === 'summary') {
        this.totalRows = this.combinedAppointmentsAndServiceRequests.length;
        this.activeItemChange({ activeItem: { modeName: selectedView } });
        this.refreshView();
      }
    },
    setServiceRequests(serviceRequests) {
      this.serviceRequests = serviceRequests;
    },
    refreshView() {
      if (!this.calendar || this.calendar.isDestroyed || this.calendar.isDestroying) return;
      let query = Cloner.deepClone(this.$route.query);
      if (!query.date) {
        query.date = moment().format('YYYY-MM-DD');
      }
      if (!query.mode) {
        query.mode = 'week';
      }
      const updated = this.updateRouteQuery(query);
      this.setDisplayDate();
      if (updated) return;
      this.displaySummaryView = query.mode == 'summary';
      this.calendarConfig.date = moment(query.date).toDate();
      this.calendarConfig.mode = query.mode;
    },
    updateRouteQuery(query) {
      query = { ...this.$route.query, ...query };
      if (JSON.stringify(this.$route.query) === JSON.stringify(query)) return false;
      this.$router.push({ query }).catch(() => {});
      return true;
    },
    activeItemChange({ activeItem }) {
      const mode = activeItem.modeName;
      this.updateRouteQuery({ mode });
    },
    dateRangeChange(event) {
      const date = moment(event.new.startDate).format('YYYY-MM-DD');
      this.updateRouteQuery({ date });
    },
    beforeEventEdit({ eventRecord }) {
      this.openModal(eventRecord.data);
      return false;
    },
    openModal(appointment) {
      this.openAppointment = appointment;
    },
    async toggleSidebar() {
      const { sidebar } = this.calendar;
      if (sidebar.isVisible) await sidebar.hide();
      else await sidebar.show();
    },
    setDisplayDate() {
      this.displayDate = this.calendar.activeView?.description;
    },

    // Calendar Event Renderers
    monthEventRenderer({ eventRecord }) {
      const { branchId } = eventRecord;
      let branch = this.myBranchMap[branchId];
      return /*html*/ `
          <span class="b-event-name"><b>${branch?.description || 'Unknown Branch'}</b></span>
      `;
    },
    dayAndWeekEventRenderer({ eventRecord }) {
      const { branchId, units } = eventRecord;
      let branch = this.myBranchMap[branchId];
      const multipleUnits = units.length > 1;
      const unit = multipleUnits ? null : units[0];
      return /*html*/ `
          <span class="b-event-name"><b>${branch?.description || 'Unknown Branch'}</b></span>
            <div>
              ${multipleUnits ? `Multiple Units<br>` : ''}
              ${unit?.fleetUnitId ? `Fleet Unit ID: ${unit.fleetUnitId}<br>` : ''}
              ${unit?.vin ? `VIN/Serial: ${unit.vin}<br>` : ''} 
            </div>
      `;
    },
    changeEvent({ source, value }) {
      if (source._ref == 'eventFilter') {
        this.$refs.requests.filter(value);
      }
    }
  }
};
</script>
