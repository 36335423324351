<template>
  <div class="mt-2 ml-3 mr-5">
    <h2 class="card-header no-bb">Service Appointments</h2>

    <responsive-search
      ref="search"
      :storage-key="storageKey"
      :fields="searchFields"
      @searchButtonClick="searchButtonClick"
      @resetButtonClick="resetButtonClick"
    />

    <b-row class="mt-1">
      <b-form-group>
        <b-input-group>
          <b-form-input
            id="filterInput"
            v-model="filter"
            variant="position-relative"
            type="search"
            placeholder="Type to Filter"
            :debounce="500"
          ></b-form-input>
        </b-input-group>
      </b-form-group>
      <b-pagination
        v-model="currentPage"
        :total-rows="totalRows"
        :per-page="pageSize"
        aria-controls="my-table"
        align="right"
        class="ml-auto w-auto"
      ></b-pagination>
    </b-row>
    <b-table
      ref="serviceCalendarTable"
      class="border mt-3"
      striped
      sticky-header="calc(69vh - 82px - 60px)"
      :items="updateSearchResults"
      :fields="fields"
      :per-page="pageSize"
      :current-page="currentPage"
      :filter="filter"
      :filter-ignored-fields="['units']"
      :sort-by.sync="sortBy"
      :sort-compare="sortCompare"
      show-empty
      empty-text="No results found."
      empty-filtered-text="No results found."
      @filtered="onFiltered"
    >
      <template #cell(appointmentStatusId)="{ item }">
        <b-badge variant="light" :style="badgeStyleByType(item)">
          {{ badgeText(item) }}
        </b-badge>
      </template>
      <template #cell(startDate)="{ item }">
        <div v-if="item.startDate">
          {{ item.startDate | date('LL') }}
          <br />
          {{ item.startDate | hourWithMinutes }} - {{ item.endDate | hourWithMinutes }}
        </div>
        <div v-if="item.serviceDateRequested !== undefined" class="note">
          Requested
          <br />
          {{ item.serviceDateRequested | dateOrEmptyString('LL') }}
        </div>
      </template>
      <template #cell(contactName)="{ item }">
        <div v-if="item.contactName">
          {{ item.contactName && item.contactName.length > 2 ? item.contactName : NA }}
          <br />
          {{ item.dateCreate | dateOrEmptyString('LL') }}
        </div>
      </template>
      <template #cell(actions)="{ item }">
        <b-button @click="openModal(item)">VIEW</b-button>
      </template>
    </b-table>
  </div>
</template>
<script>
import SearchMixin from '@/shared/mixins/SearchMixin';
import { mapGetters } from 'vuex';
import { LookupGetters } from '@/shared/store/lookup/types';
import ResponsiveSearchComponent from '@/shared/components/ResponsiveSearchComponent';
import AppointmentCssHelper from '@/shared/helpers/appointment-css-helper';
import cloner from '../../../shared/helpers/cloner';
export default {
  name: 'AppointmentSummaryComponent',
  components: {
    'responsive-search': ResponsiveSearchComponent
  },
  mixins: [SearchMixin],
  props: {
    appointmentsAndRequests: {
      type: Array,
      default: () => [],
      required: true
    }
  },
  data() {
    return {
      NA: 'N/A',
      storageKey: 'serviceCalendarSummary',
      filter: null,
      sortBy: null,
      totalRows: 0,
      currentPage: 1,
      pageSize: 50,
      fields: [
        {
          key: 'appointmentStatusId',
          label: 'status',
          tdClass: 'align-middle',
          sortable: true,
          formatter: (_value, _key, item) => {
            return this.badgeText(item);
          },
          sortByFormatted: true,
          filterByFormatted: true
        },
        { key: 'startDate', label: 'schedule', tdClass: 'align-middle', sortable: true },
        { key: 'contactName', label: 'requested by', tdClass: 'align-middle', sortable: true },
        {
          key: 'branchId',
          label: 'branch',
          tdClass: 'align-middle',
          sortable: true,
          formatter: _value => {
            return this.getBranchNameById(_value);
          }
        },
        {
          key: 'fleetUnitId',
          label: 'fleet unit id',
          tdClass: 'align-middle',
          sortable: true,
          formatter: (_value, _key, item) => {
            if (item.units?.length > 1) return 'Multiple Units';
            return item.units[0]?.fleetUnitId ?? '';
          },
          sortByFormatted: true,
          filterByFormatted: true
        },
        {
          key: 'vin',
          label: 'vin/serial',
          tdClass: 'align-middle',
          sortable: true,
          formatter: (_value, _key, item) => {
            if (item.units?.length > 1) return 'Multiple Units';
            return item.units[0]?.vin ?? '';
          },
          sortByFormatted: true,
          filterByFormatted: true
        },
        { key: 'actions', label: 'actions', tdClass: 'align-middle', sortable: false }
      ],
      searchResults: [],
      AppointmentCssHelper: AppointmentCssHelper
    };
  },
  computed: {
    ...mapGetters({
      myBranchesMap: LookupGetters.GET_MY_BRANCHES_MAP
    }),
    updateSearchResults() {
      var updatedSearchResults = this.appointmentsAndRequests;
      const query = this.$route.query;
      if (query) {
        if (query.fleetUnitId) {
          updatedSearchResults = updatedSearchResults.filter(
            result =>
              result.units
                .map(unit => unit.fleetUnitId.toLowerCase())
                .find(fleetUnitId => fleetUnitId.includes(query.fleetUnitId.toLowerCase())) !== undefined
          );
        }

        if (query.vin) {
          updatedSearchResults = updatedSearchResults.filter(
            result =>
              result.units.map(unit => unit.vin.toLowerCase()).find(vin => vin.includes(query.vin.toLowerCase())) !==
              undefined
          );
        }

        if (query.branches) {
          var branchIds;
          if (Array.isArray(query.branches)) {
            branchIds = query.branches.map(branch => branch.substring(0, 3));
          } else {
            branchIds = [query.branches.substring(0, 3)];
          }
          updatedSearchResults = updatedSearchResults.filter(result => branchIds.includes(result.branchId));
        }

        if (query.requestedBy) {
          updatedSearchResults = updatedSearchResults.filter(
            result => result.contactName && result.contactName.toLowerCase().includes(query.requestedBy.toLowerCase())
          );
        }
      }

      return updatedSearchResults;
    },
    searchFields() {
      return [
        { ...this.fleetUnitIdField },
        { ...this.vinField },
        { ...this.branchField },
        { ...this.requestedByField }
      ];
    },
    searchFieldsKeys() {
      return this.searchFields.map(field => field.key);
    }
  },
  mounted() {
    const query = this.$refs.search?.getQueryString();
    this.$emit('updateRouteQuery', query);
  },
  methods: {
    badgeText(item) {
      if (item.serviceDateRequested !== undefined) return 'REQUESTED';
      return item.appointmentStatus.name;
    },
    badgeStyleByType(appointment) {
      if (appointment.serviceDateRequested !== undefined) {
        return AppointmentCssHelper.requestBadgeStyle();
      } else {
        return AppointmentCssHelper.badgeStyle(appointment);
      }
    },
    sortCompare(a, b, key) {
      if (key === 'vin' || key === 'fleetUnitId') {
        if (b.units.length > 1) {
          if (a.units.length > 1) {
            return 0;
          } else {
            return -1;
          }
        } else {
          if (a.units.length > 1) {
            return 1;
          } else {
            return ('' + a.units[0][key]).localeCompare(b.units[0][key]);
          }
        }
      } else {
        return false;
      }
    },
    onFiltered(filteredItems) {
      //Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    openModal(appointment) {
      this.$emit('openModal', appointment);
    },
    getBranchNameById(branchId) {
      return this.myBranchesMap[branchId]?.description;
    },
    searchButtonClick(query) {
      this.$emit('updateRouteQuery', query);
    },
    resetButtonClick() {
      this.filter = '';
      this.sortBy = '';
      let query = cloner.deepClone(this.$route.query);
      for (const key of Object.keys(query)) {
        if (this.searchFieldsKeys.includes(key)) {
          delete query[key];
        }
      }
      this.$router.push({ query }).catch(() => {});
    }
  }
};
</script>
