import { EventModel, ResourceModel } from '@bryntum/calendar';

export class Appointment extends EventModel {
  static get fields() {
    return [
      { name: 'appointmentId', defaultValue: 0 },
      { name: 'id', dataSource: 'appointmentId' },
      { name: 'name', dataSource: 'complaint' },
      { name: 'appointmentStatusId', defaultValue: 1 },
      { name: 'resourceId', dataSource: 'appointmentStatusId' },
      { name: 'units', defaultValue: null },
      { name: 'contactEmail', defaultValue: null },
      { name: 'contactName', defaultValue: null },
      { name: 'serviceRequestId', defaultValue: 0 }
    ];
  }
}
export class AppointmentStatus extends ResourceModel {
  static get fields() {
    return [
      { name: 'appointmentStatusId', defaultValue: 1 },
      { name: 'id', dataSource: 'appointmentStatusId' }
    ];
  }
}

export const appointmentFilter = (record, value) => {
  return (
    record.name.toLowerCase().includes(value) ||
    record.branchId?.includes(value) ||
    record.units?.some(
      u => u.fleetUnitId.includes(value) || u.vin.includes(value) || u.customerDisplay.toLowerCase().includes(value)
    )
  );
};

export const calendarConfig = {
  mode: 'week',
  tbar: null,
  eventTooltipFeature: {
    tools: {
      delete: false,
      edit: false
    }
  },
  dragFeature: false,
  eventEditFeature: {
    readOnly: true
  },
  sidebar: {
    items: {
      eventFilter: {
        bubbleEvents: { change: true },
        placeholder: 'Filter Appointments',
        filterFunction: appointmentFilter
      }
    },
    hideAnimation: {
      'margin-left': {
        to: '-320px',
        duration: '.4s',
        delay: '0s'
      }
    },
    showAnimation: {
      'margin-left': {
        from: '-320px',
        to: '0px',
        duration: '.4s',
        delay: '0s'
      }
    }
  },
  eventStore: {
    modelClass: Appointment
  },
  resourceStore: {
    modelClass: AppointmentStatus
  }
};
